

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import SingleEmployee from "@/components/Zoinks/Employees/SingleEmployee.vue";
import HireEmployees from "@/components/Zoinks/Employees/HireEmployees.vue";
import { Employee } from '@/logic/Zoinks/Employees/Employee';
import { GameStatus } from '@/logic/Zoinks/Games/GameStatus';
import { EmployeeSearchTypeEnum } from '@/logic/Zoinks/Employees/EmployeeSearchTypeEnum';

@Options({
  components: {
    SingleEmployee,
    HireEmployees,
  },
})
export default class EmployeesPanel extends Vue {
    store = useStore();

    get employees(): Employee[] {
        return this.store.state.employeesModule.employees;
    }

    get showHireEmployeeButton(): boolean {
        return (this.store.state.employeesModule.employees.length < this.store.state.employeesModule.maxEmployees)
            && this.store.state.employeesModule.activeSearchType === EmployeeSearchTypeEnum.NoActiveSearch;
    }

    get showEmployeeHireSection(): boolean {
        return !this.showHireEmployeeButton && this.store.state.employeesModule.activeSearchType !== EmployeeSearchTypeEnum.NoActiveSearch;
    }

    get showEmployees(): boolean {
        return (this.store.state.gamesModule.archivedGames.length > 0)
            || ((this.store.state.gamesModule.activeGames.length > 0) && (this.store.state.gamesModule.activeGames.some(g => g.status !== GameStatus.Setup)));
    }

    hireEmployees(): void {
        this.store.commit("setActiveSearchType", EmployeeSearchTypeEnum.SelectingSearch);
    }
}

