

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import CurrentGamesPanel from "@/components/Zoinks/Games/CurrentGamesPanel.vue";
import EmployeesPanel from '@/components/Zoinks/Employees/EmployeesPanel.vue';
import MessagesPanel from '@/components/Zoinks/Messages/MessagesPanel.vue';

@Options({
  components: {
    CurrentGamesPanel,
    EmployeesPanel,
    MessagesPanel,
  },
})
export default class TheZoinksMakeAGame extends Vue {
    store = useStore();

    mounted(): void {
        this.store.dispatch("startGameTimer");
    }
}

