import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bf69e25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bar-wrapper" }
const _hoisted_2 = { class: "bar" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "filled-part",
        style: _normalizeStyle('width: ' + _ctx.progressPixels + '%;')
      }, null, 4),
      _createElementVNode("div", {
        class: "empty-part",
        style: _normalizeStyle('width: ' + (_ctx.progressBarWidth - _ctx.progressPixels) + '%;')
      }, null, 4)
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}