

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import SingleMessage from "@/components/Zoinks/Messages/SingleMessage.vue";
import { Message } from '@/logic/Zoinks/Messages/Message';

@Options({
    props: {
        gameId: Number,
    },
  components: {
    SingleMessage,
  },
})
export default class MessagesPanel extends Vue {
    store = useStore();
    gameId = 0;

    get currentMessages(): Message[] {
        return this.store.state.messagesModule.currentMessages;
    }

    get archivedMessages(): Message[] {
        return this.store.state.messagesModule.archivedMessages;
    }

    get activeMessage(): Message | null {
        return this.currentMessages.length ? this.currentMessages[0] : null;
    }

    get showMessages(): boolean {
        return this.currentMessages.length !== 0 || this.archivedMessages.length !== 0;
    }

    get remainingMessagesDisplay(): string {
        const numHiddenMessages = this.currentMessages.length - 1;
        if (numHiddenMessages === 1) {
            return numHiddenMessages + " remaining message";
        } else if (numHiddenMessages > 1) {
            return numHiddenMessages + " remaining messages";
        }
        return "";
    }

    get nextButtonText(): string {
        return this.currentMessages.length > 1 ? "Next" : "Close";
    }

     nextMessage(): void {
        this.store.commit("messageRead", this.activeMessage);
    }
}

