

import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import { Message } from '@/logic/Zoinks/Messages/Message';
import InlineSvg from 'vue-inline-svg';

@Options({
    props: {
        messageIndex: Number,
    },
    components: {
        InlineSvg,
    },
})
export default class SingleMessage extends Vue {
    store = useStore();
    messageIndex = 0;

    get message(): Message | null {
        return this.store.state.messagesModule.currentMessages[this.messageIndex];
    }
}

