

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import PhasePortionSetup from './PhasePortionSetup.vue';
import { Game } from '@/logic/Zoinks/Games/Game';

@Options({
  props: {
    gameId: Number,
    phaseNumber: Number,
  },
  components: {
    PhasePortionSetup,
  },
})
export default class PhaseSetup extends Vue {
    gameId = 0;
    phaseNumber = 0;
    store = useStore();

    get game(): Game {
        return this.store.state.gamesModule.activeGames.find(g => g.id === this.gameId)!;
    }

    get phaseName(): string {
      return this.game.getPhase(this.phaseNumber).phaseMeta.name;
    }
}

