

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    progress: Number,
    description: String,
  },
})
export default class ProgressBar extends Vue {
    progress = 0;
    description = "";
    progressBarWidth = 100;

    get progressPixels(): number {
        const pixels = this.progress * this.progressBarWidth;
        return pixels > this.progressBarWidth ? this.progressBarWidth : pixels < 0 ? 0 : pixels;
    }
}

