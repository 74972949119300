import { PhaseMeta } from "./PhaseMeta";
import { PhasePortion } from "./PhasePortion";

export class Phase {
	public phaseMeta: PhaseMeta;
	public startTime = 0;
	public progress = 0;
    public devPortion: PhasePortion;
    public designPortion: PhasePortion;
    public writingPortion: PhasePortion;

    public constructor(phaseMeta: PhaseMeta) {
		this.phaseMeta = phaseMeta;
        this.devPortion = new PhasePortion(phaseMeta.devPortion);
        this.designPortion = new PhasePortion(phaseMeta.designPortion);
        this.writingPortion = new PhasePortion(phaseMeta.writingPortion);
    }

    public getPhasePortion(phasePortionNumber: number): PhasePortion {
		if (phasePortionNumber === 1) {
			return this.devPortion;
		} else if (phasePortionNumber === 2) {
			return this.designPortion;
		} else if (phasePortionNumber === 3) {
			return this.writingPortion;
		} else {
			throw new Error('Invalid phase portion number.');
		}
	}
}