

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import { Employee } from '@/logic/Zoinks/Employees/Employee';
import EmployeeStats from "@/components/Zoinks/Employees/EmployeeStats.vue";

@Options({
    props: {
        employeeId: Number,
    },
    components: {
        EmployeeStats,
    },
})
export default class SingleEmployee extends Vue {
    store = useStore();
    employeeId = 0;

    get employee(): Employee | null {
        return this.store.state.employeesModule.employees.find(e => e.id === this.employeeId) ?? null;
    }

    fireEmployee(): void {
        this.store.dispatch("fireEmployee", this.employeeId);
    }
}

