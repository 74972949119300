

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import SingleGame from "@/components/Zoinks/Games/SingleGame.vue";
import { Game } from '@/logic/Zoinks/Games/Game';
import InlineSvg from 'vue-inline-svg';

@Options({
  components: {
    SingleGame,
    InlineSvg
  },
})
export default class CurrentGamesPanel extends Vue {
    store = useStore();

    // get thang() {
    //     console.log("okay so its:");
    //     console.log(testSvg);
    //     return testSvg;
    // }

    get activeGames(): Game[] {
        return this.store.state.gamesModule.activeGames;
    }

    get showMakeAGame(): boolean {
        return this.allowMultipleActiveGames || this.activeGames.length === 0;
    }

    get allowMultipleActiveGames(): boolean {
        return this.store.state.gamesModule.allowMultipleActiveGames;
    }

    newGame(): void {
        this.store.commit("addActiveGame", new Game());
    }
}

