import { GameMechanic } from "./GameMechanic";
import { GameStatus } from "./GameStatus";
import { GameTheme } from "./GameTheme";
import { Phase } from "./Phase";
import { PhaseMeta } from "./PhaseMeta";

export class Game {
	public readonly id: number;
	public name: string;
	public status: GameStatus;
    public progress: number;
	public mechanic: GameMechanic;
	public theme: GameTheme;

	private phase1: Phase;
	private phase2: Phase;
	private phase3: Phase;

	private static lastId = 0;

	public constructor() {
		Game.lastId++;

		this.id = Game.lastId;
        this.name = "Breadmaker's Revenge!";
		this.status = GameStatus.Setup;
        this.progress = 0;
		this.theme = GameTheme.Cooking;
		this.mechanic = GameMechanic.ActionTiming;

		this.phase1 = new Phase(PhaseMeta.Phase1);
		this.phase2 = new Phase(PhaseMeta.Phase2);
		this.phase3 = new Phase(PhaseMeta.Phase3);
	}

	public getPhase(phaseNumber: number): Phase {
		if (phaseNumber === 1) {
			return this.phase1;
		} else if (phaseNumber === 2) {
			return this.phase2;
		} else if (phaseNumber === 3) {
			return this.phase3;
		} else {
			throw new Error('Invalid phase number.');
		}
	}
}