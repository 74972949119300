

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import SingleEmployee from "@/components/Zoinks/Employees/SingleEmployee.vue";
import EmployeeStats from "@/components/Zoinks/Employees/EmployeeStats.vue";
import ProgressBar from "@/components/Zoinks/General/ProgressBar.vue";
import { EmployeeSearchTypeEnum } from '@/logic/Zoinks/Employees/EmployeeSearchTypeEnum';
import { EmployeeSearchTypeHelper } from '@/logic/Zoinks/Employees/EmployeeSearchTypeHelper';
import { Employee } from '@/logic/Zoinks/Employees/Employee';

@Options({
  components: {
    SingleEmployee,
    ProgressBar,
    EmployeeStats,
  },
})
export default class EmployeesPanel extends Vue {
    store = useStore();

    get showSetSearchSettings(): boolean {
        return this.store.state.employeesModule.activeSearchType === EmployeeSearchTypeEnum.SelectingSearch;
    }

    get employeeSearchValues(): number[]{
        // Only get search types whose required recruiting level isn't too high:
        return Array.from(this.store.state.employeesModule.allSearchTypesMap.values())
            .filter(s => s.requiredRecruitingLevel <= this.store.state.employeesModule.recruitingLevel)
            .map(s => s.searchType);
    }

    get selectedSearchApproach(): number {
        return this.store.state.employeesModule.lastSearchType;
    }

    set selectedSearchApproach(index: number) {
        this.store.commit("setLastSearchType", index);
    }

    get showSearchInProgress(): boolean {
        if (!EmployeeSearchTypeHelper.IsActiveSearch(this.store.state.employeesModule.activeSearchType)) {
            return false;
        }
        return this.store.state.employeesModule.activeSearchProgress !== 1;
    }

    get employeeSearchProgress(): number {
        return this.store.state.employeesModule.activeSearchProgress;
    }

    get searchApproachDescription(): string {
        if (!this.showSearchInProgress) {
            return "";
        }
        return this.store.state.employeesModule.allSearchTypesMap.get(this.store.state.employeesModule.activeSearchType)?.description ?? "";
    }

    get candidates(): Employee[] {
        return this.store.state.employeesModule.candidates;
    }

    employeeSearchName(value: number): string {
        return this.store.state.employeesModule.allSearchTypesMap.get(value)?.name ?? "";
    }

    startSearch() {
        this.store.commit("setActiveSearchType", this.store.state.employeesModule.lastSearchType);
    }

    cancelSearch() {
        this.store.commit("setActiveSearchType", EmployeeSearchTypeEnum.NoActiveSearch);
    }

    hireCandidate(index: number): void {
        this.store.dispatch("hireCandidate", index);
    }
}

