

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import { Employee } from '@/logic/Zoinks/Employees/Employee';
import InlineSvg from 'vue-inline-svg';

@Options({
    props: {
        employeeId: Number,
        candidateMode: Boolean,
    },
    components: {
        InlineSvg,
    },
})
export default class EmployeeStats extends Vue {
    store = useStore();
    employeeId = 0;
    candidateMode = false;

    get employee(): Employee | null {
        if (this.candidateMode) {
            return this.store.state.employeesModule.candidates.find(e => e.id === this.employeeId) ?? null;
        } else {
            return this.store.state.employeesModule.employees.find(e => e.id === this.employeeId) ?? null;
        }
    }
}

