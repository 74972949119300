import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-major" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhasePortionSetup = _resolveComponent("PhasePortionSetup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.phaseName), 1),
    _createVNode(_component_PhasePortionSetup, {
      gameId: _ctx.gameId,
      phaseNumber: _ctx.phaseNumber,
      phasePortionNumber: 1
    }, null, 8, ["gameId", "phaseNumber"]),
    _createVNode(_component_PhasePortionSetup, {
      gameId: _ctx.gameId,
      phaseNumber: _ctx.phaseNumber,
      phasePortionNumber: 2
    }, null, 8, ["gameId", "phaseNumber"]),
    _createVNode(_component_PhasePortionSetup, {
      gameId: _ctx.gameId,
      phaseNumber: _ctx.phaseNumber,
      phasePortionNumber: 3
    }, null, 8, ["gameId", "phaseNumber"])
  ]))
}