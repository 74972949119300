import { PhasePortionMeta } from "./PhasePortionMeta";

export class PhasePortion {
    public phasePortionMeta: PhasePortionMeta;
    public assignedEmployeeId: number | null;

    public constructor(phasePortionMeta: PhasePortionMeta) {
        this.phasePortionMeta = phasePortionMeta;
        this.assignedEmployeeId = null;
    }
}