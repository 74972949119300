import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-002da464"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "main-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleGame = _resolveComponent("SingleGame")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.allowMultipleActiveGames ? "Current Games" : "Current Game"), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeGames, (game, index) => {
        return (_openBlock(), _createBlock(_component_SingleGame, {
          key: index,
          gameId: _ctx.activeGames[index].id
        }, null, 8, ["gameId"]))
      }), 128)),
      (_ctx.showMakeAGame)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.newGame && _ctx.newGame(...args)))
            }, "Make a Game")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}