

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import { Game } from '@/logic/Zoinks/Games/Game';
import { Employee } from '@/logic/Zoinks/Employees/Employee';
import { PhasePortion } from '@/logic/Zoinks/Games/PhasePortion';
import { PhasePortionEmployeeData } from '@/logic/Zoinks/Games/PhasePortionEmployeeData';
import InlineSvg from 'vue-inline-svg';

@Options({
  props: {
    gameId: Number,
    phaseNumber: Number,
    phasePortionNumber: Number,
  },
  components: {
    InlineSvg,
  },
})
export default class PhasePortionSetup extends Vue {
    gameId = 0;
    phaseNumber = 0;
    phasePortionNumber = 0;
    store = useStore();

    get employeeAssignedSelectId(): string {
        return `assigned_${this.gameId}_${this.phaseNumber}_${this.phasePortionNumber}`;
    }

    get phasePortion(): PhasePortion {
        return this.game.getPhase(this.phaseNumber).getPhasePortion(this.phasePortionNumber);
    }

    get selectedEmployeeId(): number {
        return this.phasePortion.assignedEmployeeId ?? -1;
    }

    set selectedEmployeeId(employeeId: number) {
        this.store.commit("setPhasePortionEmployee", new PhasePortionEmployeeData(this.gameId, this.phaseNumber, this.phasePortionNumber, employeeId === -1 ? null : employeeId));
    }

    get phasePortionName(): string {
        return this.phasePortion.phasePortionMeta.name;
    }

    get employees(): Employee[] {
        return this.store.state.employeesModule.employees;
    }

    get game(): Game {
        return this.store.state.gamesModule.activeGames.find(g => g.id === this.gameId)!;
    }

    mounted(): void {
        if (this.employees.length === 1) {
            this.selectedEmployeeId = this.employees[0].id;
        }
    }
}

