

import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import { GameMechanic } from "@/logic/Zoinks/Games/GameMechanic";
import { GameTheme } from '@/logic/Zoinks/Games/GameTheme';
import { Game } from '@/logic/Zoinks/Games/Game';
import { GameStatus } from '@/logic/Zoinks/Games/GameStatus';
import PhaseSetup from "@/components/Zoinks/Games/PhaseSetup.vue";
import PhaseInProgress from "@/components/Zoinks/Games/PhaseInProgress.vue";
import { PhaseMeta } from '@/logic/Zoinks/Games/PhaseMeta';

@Options({
    props: {
        gameId: Number,
    },
    components: {
        PhaseSetup,
        PhaseInProgress,
    }
})
export default class SingleGame extends Vue {
    store = useStore();
    gameId = 0;

    get game(): Game | null {
        return this.store.state.gamesModule.activeGames.find((g) => g.id === this.gameId) ?? null;
    }

    get gameThemes(): GameTheme[] {
        return this.store.state.gamesModule.allThemes;
    }

    get gameMechanics(): GameMechanic[] {
        return this.store.state.gamesModule.allMechanics;
    }

    get gameStatus(): GameStatus {
        return this.game?.status ?? GameStatus.None;
    }

    get showGameSetup(): boolean {
        return this.gameStatus === GameStatus.Setup;
    }

    get noEmployees(): boolean {
        return this.store.state.employeesModule.employees.length === 0;
    }

    get showPhase1Setup(): boolean {
        return this.gameStatus === GameStatus.Phase1Setup;
    }

    get showPhase2Setup(): boolean {
        return this.gameStatus === GameStatus.Phase2Setup;
    }

    get showPhase3Setup(): boolean {
        return this.gameStatus === GameStatus.Phase3Setup;
    }

    get showPhase1InProgress(): boolean {
        return this.gameStatus === GameStatus.Phase1InProgress;
    }

    get showPhase2InProgress(): boolean {
        return this.gameStatus === GameStatus.Phase2InProgress;
    }

    get showPhase3InProgress(): boolean {
        return this.gameStatus === GameStatus.Phase3InProgress;
    }

    get gameName(): string {
        return this.game?.name ?? "";
    }

    set gameName(name: string) {
        this.store.commit("setGameName", { id: this.gameId, newValue: name });
    }

    get gameThemeName(): string {
        return this.game ? this.game.theme.name : "";
    }

    get selectedGameThemeIndex(): number {
        return this.game ? this.gameThemes.indexOf(this.game.theme) : -1;
    }

    set selectedGameThemeIndex(index: number) {
        this.store.commit("setGameTheme", { id: this.gameId, newValue: this.store.state.gamesModule.allThemes[index] });
    }

    get gameMechanicName(): string {
        return this.game ? this.game.mechanic.name : "";
    }

    get selectedGameMechanicIndex(): number {
        return this.game ? this.gameMechanics.indexOf(this.game.mechanic) : -1;
    }

    set selectedGameMechanicIndex(index: number) {
        this.store.commit("setGameMechanic", { id: this.gameId, newValue: this.store.state.gamesModule.allMechanics[index] });
    }

    get phaseName(): string {
        return PhaseMeta.getPhaseNameByGameStatus(this.gameStatus);
    }

    get disableStartPhasePortionButton(): boolean {
        if (this.showPhase1Setup || this.showPhase2Setup || this.showPhase3Setup) {
            const phaseNumber = PhaseMeta.getPhaseNumberByGameStatus(this.gameStatus);
            const phase = this.game!.getPhase(phaseNumber);
            if (phase.devPortion.assignedEmployeeId && phase.designPortion.assignedEmployeeId && phase.writingPortion.assignedEmployeeId) {
                return false;
            }
        }
        return true;
    }

    cancelGame(): void {
        this.store.commit("cancelActiveGame", this.gameId);
    }

    startGame(): void {
        this.store.commit("setGameStatus", { id: this.gameId, newValue: GameStatus.Phase1Setup });
    }

    abandonGame(): void {
        this.store.commit("setGameStatus", { id: this.gameId, newValue: GameStatus.Abandoned });
    }

    startPhasePortion(): void {
        let inProgressStatus: GameStatus | null = null;
        if (this.gameStatus === GameStatus.Phase1Setup) {
            inProgressStatus = GameStatus.Phase1InProgress;
        } else if (this.gameStatus === GameStatus.Phase2Setup) {
            inProgressStatus = GameStatus.Phase2InProgress;
        } else if (this.gameStatus === GameStatus.Phase3Setup) {
            inProgressStatus = GameStatus.Phase3InProgress;
        }
        if (inProgressStatus) {
            this.store.commit("setGameStatus", { id: this.gameId, newValue: inProgressStatus });
        }
    }
}

